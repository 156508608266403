* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* html, body {
    overflow-x: hidden;
} */

:root {
    --mainWhite: #fff;
    --mainBlue: #00113C;
    /* Testing */
    --testRed: #f9c9c97b;
}

/* -----------HOME-------------- */

.home {
    color: var(--mainBlue);
    font-family: 'CircularStd', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
}

.home p {
    padding: 16px 0 0 0;
    font-size: 1.5rem;
    letter-spacing: -0.5px;
}

.btn-join {
    color: white;
    background: linear-gradient(to right, #49DAE4, #21B1F8);
    border: none;
    text-align: center;
    height: 57px;
    width: 240px;
    border-radius: 12px;
    margin: 52px 0 42px 0;
    font-family: 'CircularStd', Arial, sans-serif;
    font-size: 1rem;
    font-weight: bold;
}

.btn-beta {
    margin-left: 10px;
    width: 140px;
    background: rgb(210,210,210);
    background: linear-gradient(90deg, #e4a149, #f89b21)
}

.flick-logo {
    width: 148px;
}

.social-icon {
    margin: 0px 12px 0px 0px;
    opacity: 0.3;
}

/* -----------MOBILE-------------- */

@media only screen and (max-width: 768px) {

    /* .home {
        background-color: var(--testRed);
    } */

    .flick-logo {
        width: 88px;
    }

    .btn-join {
        color: white;
        background: linear-gradient(to right, #49DAE4, #21B1F8);
        border: none;
        text-align: center;
        height: 56px;
        width: 200px;
        border-radius: 12px;
        margin: 49px 0 0 0;
        font-family: 'CircularStd', Arial, sans-serif;
        font-size: 0.9rem;
        font-weight: bold;
    }

    .btn-beta {
        margin-left: 10px;
        margin-top: 10px;
        width: 140px;
        background: rgb(210,210,210);
        background: linear-gradient(90deg, #e4a149, #f89b21)
    }

}