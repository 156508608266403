/*
 Weight         : Style
 normal (400)   : Book
 500            : Medium
 bold (700)     : Bold
 900            : Black
 */

 /* Book */
 @font-face {
    font-family: 'CircularStd';
    src: url('./fonts/CircularStd-Book.woff2') format('woff2'),
        url('./fonts/CircularStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('./fonts/CircularStd-BookItalic.woff2') format('woff2'),
        url('./fonts/CircularStd-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

/* Medium */
@font-face {
    font-family: 'CircularStd';
    src: url('./fonts/CircularStd-Medium.woff2') format('woff2'),
        url('./fonts/CircularStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('./fonts/CircularStd-MediumItalic.woff2') format('woff2'),
        url('./fonts/CircularStd-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

/* Bold */
@font-face {
    font-family: 'CircularStd';
    src: url('./fonts/CircularStd-Bold.woff2') format('woff2'),
        url('./fonts/CircularStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('./fonts/CircularStd-BoldItalic.woff2') format('woff2'),
        url('./fonts/CircularStd-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

/* Black */
@font-face {
    font-family: 'CircularStd';
    src: url('./fonts/CircularStd-Black.woff2') format('woff2'),
        url('./fonts/CircularStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('./fonts/CircularStd-BlackItalic.woff2') format('woff2'),
        url('./fonts/CircularStd-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}
